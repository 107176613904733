import React from 'react';
import ContactSection from './ContactSection.js'
function ContactPage() {
    return (
        <div>
            <ContactSection />
        </div>
    )
}

export default ContactPage;